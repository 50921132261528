import { AddCircle } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Loader } from '../components/Loader';
import { ClientTable } from '../components/ClientTable';
import { ClientCreateModal } from '../components/ClientCreateModal';
import { fetchClientsThunk, clearCredentials } from '../features/clientsSlice';

const Clients = ({ children }) => {
  const [isOpen, setOpen] = useState(false);
  const idToken = useSelector((state) => state.user.idToken);
  const clients = useSelector((state) => state.clients.value);
  const loading = useSelector((state) => state.clients.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (idToken) {
      dispatch(fetchClientsThunk(idToken));
    }
  }, [dispatch, idToken]);

  if (loading) {
    return <Loader />;
  }

  const onCloseFn = () => {
    if (idToken) {
      dispatch(clearCredentials());
    }
    setOpen(false);
  };
  if (idToken) {
    return (
      <Box>
        <Button sx={{ marginBottom: '25px' }} variant="outlined" onClick={() => setOpen(true)}>
          <AddCircle />
          &nbsp; New
        </Button>
        <ClientCreateModal title="Create New Client:" isOpen={isOpen} onCloseFn={onCloseFn} />
        <ClientTable clients={clients} />
      </Box>
    );
  }
};

export default Clients;
