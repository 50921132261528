import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchClients, createClient, deleteClient } from '../services/clients';


export const fetchClientsThunk = createAsyncThunk('clientsApi/fetchClientsThunkStatus', async (idToken) => {
  return await fetchClients(idToken)
});

export const createClientThunk = createAsyncThunk('clientsApi/createClientThunkStatus', async ({idToken, data}, thunkAPI) => {
  const result = await createClient(idToken, data)
  thunkAPI.dispatch(fetchClientsThunk(idToken))
  return result
});

export const deleteClientThunk = createAsyncThunk('clientsApi/deleteClientThunkStatus', async ({idToken, clientId}, thunkAPI) => {
  await deleteClient(idToken, clientId)
  thunkAPI.dispatch(fetchClientsThunk(idToken))
});

const initialState = {
  value: [],
  loading: false,
  error: null,
  newClientId: '',
  newClientSecret: '',
};

export const clientsApiSlice = createSlice({
  name: 'clientsApi',
  initialState,
  reducers: {
    clearCredentials: (state, action) => {
      state.newClientId = ''
      state.newClientSecret = ''
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClientsThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchClientsThunk.fulfilled, (state, action) => {
      state.value = action.payload.results;
      state.loading = false;
    });
    builder.addCase(createClientThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createClientThunk.fulfilled, (state, action) => {
      state.newClientId = action.payload.clientId;
      state.newClientSecret = action.payload.clientSecret;
      state.loading = false;
    });
    builder.addCase(deleteClientThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteClientThunk.fulfilled, (state, action) => {
      state.loading = false;
    });
  }
});

export const { clearCredentials } = clientsApiSlice.actions;

export default clientsApiSlice.reducer;
