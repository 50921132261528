import React, { useState } from 'react';
import { DeleteForever } from '@mui/icons-material';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import { deleteClientThunk } from '../features/clientsSlice';
import DeleteClientModal from './DeleteClientModal';

export const ClientTable = ({ clients }) => {
  const initialData = {};
  const [deleteClient, setDeleteClient] = useState(initialData);

  const dispatch = useDispatch();
  const idToken = useSelector((state) => state.user.idToken);
  const deleteFn = (clientId) => {
    setDeleteClient(initialData);
    dispatch(deleteClientThunk({ idToken, clientId }));
  };

  if (clients.length < 1) {
    return <div></div>;
  }
  return (
    <TableContainer component={Paper}>
      <DeleteClientModal
        clientName={deleteClient.clientName}
        onSubmitFn={() => deleteFn(deleteClient.clientId)}
        onCloseFn={() => setDeleteClient(initialData)}
      />
      <Table stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Client Name</TableCell>
            <TableCell align="right">Client ID</TableCell>
            <TableCell align="right">SONIFI Site ID</TableCell>
            <TableCell align="right">Access Level</TableCell>
            <TableCell align="right">Require Pings?</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clients.map((row) => (
            <TableRow
              key={row.clientName}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.clientName}
              </TableCell>
              <TableCell align="right">{row.clientId}</TableCell>
              <TableCell align="right">{row.siteId}</TableCell>
              <TableCell align="right">{row.access.join(', ')}</TableCell>
              <TableCell align="right">
                {row.requirePings ? 'Yes' : 'No'}
              </TableCell>
              <TableCell align="right">
                <Button
                  variant="outlined"
                  color="error"
                  onClickCapture={() => setDeleteClient(row)}
                >
                  <DeleteForever />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
